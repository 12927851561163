import { Layout } from 'antd';
import stylemateLogo from '../assests/stylemate-logo.svg'
import CTA from './cta'

const { Header } = Layout;

function HeaderComponent() {
  return (
      <Header
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          background: '#fff'
        }}
        className="px-6 md:px-16"
      >
        <img alt="stylemate" src={stylemateLogo} className="w-[120px] md:w-[155px]" />
        <CTA className="h-8 w-32 md:h-12 md:w-52"/>
      </Header>
  );  
}

export default HeaderComponent;
