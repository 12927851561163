import { Row, Col } from 'antd';
import personalStylistIllustration from '../assests/personal-stylist-illustration.svg'
import wardrobeManagementIllustration from '../assests/wardrobe-management-illustration.svg'
import virtualTryonIllustration from '../assests/vertual-tryon-illustration.svg'
import shoppingExpIllustration from '../assests/shopping-exp-illustration.svg'

function Feature({ img, title, description, className }: { img: any, title: string, description: string, className?: string }) {
  return(
    <Row gutter={16} className={`flex items-center flex-col md:flex-row mt-6 md:mt-16 ${className}`}>
      <Col className={`illustration-container ${className ? 'flex justify-end' : ''}`} span={24} md={12}>
        <img src={img} alt="stylemate" className="w-[150px] md:w-[428px]"/>
      </Col>
      <Col className="text-container text-center md:text-left mt-6 md:mt-0" span={24} md={12}>
        <div className="title text-base md:text-2xl font-bold">{title}</div>
        <div className="description text-sm md:text-xl mt-4">
          {description}
        </div>
      </Col>
    </Row>
  )
}

function Features({ className="" }: { className?: string }) {
  return (
    <Row gutter={16} className={`flex md:flex-row flex-col ${className}`}>
      <Row gutter={16} className="section-title-container flex items-center justify-center w-full">
        <Col className="section-title h-16 md:h-20 flex flex-row items-center justify-center" span={24}>
          <span className="text-2xl md:text-5xl text-center md:text-left">Explore Our <b>Fashion-Forward</b> Features</span>
        </Col>
      </Row>
      <Feature 
        img={personalStylistIllustration} 
        title="Personal stylist" 
        description="Personal stylist will help you to find the perfect style according to your personality with their style knowledge."
      />
      <Feature 
        img={wardrobeManagementIllustration} 
        title="Wardrobe management" 
        description="We help you organise and manage your wardrobe, making it easy to mix and match items and planed outfits effortlessly."
        className="md:flex-row-reverse"
      />
      <Feature 
        img={virtualTryonIllustration} 
        title="Virtual Try-On" 
        description="Incorporating augmented reality (AR) technology, StyleMate allows users to virtually try on clothing items before making a purchase, helping them visualize how different pieces will look and fit."
      />
      <Feature 
        img={shoppingExpIllustration} 
        title="Seamless Shopping Experience" 
        description="Stylemate seamlessly integrates with online retailers, allowing users to shop directly from the app with ease and convenience."
        className="md:flex-row-reverse"
      />
    </Row>
  );  
}

export default Features;


