import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './containers/LandingPage';
import OutfitCollage from './containers/OutfitCollage';

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/outfit" element={<OutfitCollage />} />
        </Routes>
      </div>
    </Router>
  );  
}

export default App;

