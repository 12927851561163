import { Layout } from 'antd';
import stylemateLogoWhite from '../assests/stylemate-logo-white.svg'
import fbIcon from '../assests/fb-icon.svg'
import instaIcon from '../assests/insta-icon.svg'
import linkedinIcon from '../assests/linkedin-icon.svg'
import mailIcon from '../assests/mail-icon.svg'
// import twitterIcon from '../assests/twitter-icon.svg'

const { Footer } = Layout;

function FooterComponent({ className = "" }) {
  return (
    <Footer className={`bg-black flex flex-col z-20 ${className}`}>
      <div className="footer-logo flex justify-center md:justify-start">
        <img src={stylemateLogoWhite} alt="stylemate" className="h-20 ml-[-5px]"/>
      </div>
      <div className="contact-us flex mt-8 justify-center md:justify-start">
        <a href="https://www.facebook.com/profile.php?viewas=100000686899395&id=61559103487609">
          <img src={fbIcon} alt="stylemate" className="mr-3"/>
        </a>
        <a href="https://www.instagram.com/_yourstylemate">
          <img src={instaIcon} alt="stylemate" className="mr-3"/>
        </a>
        {/* <a href="google.com"><img src={twitterIcon} alt="stylemate" className="mr-3"/></a> */}
        <a href="https://www.linkedin.com/company/yourstylemate">
          <img src={linkedinIcon} alt="stylemate" className="mr-3"/>
        </a>
        <a href="mailto:info@yourstylemate.com">
          <img src={mailIcon} alt="stylemate" className="mr-3"/>
        </a>
      </div>
    </Footer>
  );  
}

export default FooterComponent;


