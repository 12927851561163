import React from 'react';
import { Layout } from 'antd';
import Header from '../components/header';
import Content from '../components/content';
import Footer from '../components/footer';

const LandingPage = () => (
<div className="container">
  <Layout className="" style={{ background: '#fff' }}>
    <Header />
    <Content />
    <Footer className="mt-6 md:mt-44"/>
  </Layout>
</div>
)

export default LandingPage;
