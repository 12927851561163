import React from 'react';
import { useEffect, useState } from 'react';

const OutfitCollage = () => {
  const [outfit, setOutfit] = useState({});
    useEffect(() => {
        console.log('OutfitCollage mounted');
        fetch('https://jsonplaceholder.typicode.com/todos/1')
        .then(response => response.json())
        .then(json => setOutfit(json))
        return () => {
            console.log('OutfitCollage unmounted');
        }
    }, []);

  return (<div>
    <h1>OutfitCollage</h1>
    <p>{JSON.stringify(outfit)}</p>
  </div>)
}

export default OutfitCollage;

