import { Layout } from 'antd';
import Intro from './intro';
import Features from './features';
import Process from './process';
import Outro from './outro';
import bgPatchRed from '../assests/background-patch.svg'
import bgPatchBlue from '../assests/bg-patch-blue.svg'

const { Content } = Layout;

function ContentComponent() {
  return (
    <Content className="px-6 md:px-16">
      <img src={bgPatchRed} alt="stylemate" style={{ height: '300px' }} className="hidden md:block fixed top-20 left-48 z-10"/>
      <img src={bgPatchBlue} alt="stylemate" style={{ height: '300px' }} className="hidden md:block fixed top-80 right-6 z-10"/>
      {/* <img src={bgPatch} alt="stylemate" style={{ height: '300px' }} className="fixed bottom-0 left-0 z-10"/> */}
      <img src={bgPatchRed} alt="stylemate" style={{ height: '300px' }} className="hidden md:block fixed bottom-20 right-[380px] z-10"/>
      <Intro />
      <Process className="mt-11 md:mt-24" />
      <Features className="mt-6 md:mt-24" />
      <Outro className="mt-11 md:mt-28 "/>
    </Content>
  );  
}

export default ContentComponent;



