import { Row, Col } from 'antd';
import outroIllustration from '../assests/outro-illustration.svg'
import CTA from './cta';

function Outro({ className="" }: { className?: string }) {
  return (
    <Row gutter={16} className={`flex md:flex-row flex-col justify-center ${className}`}>
      <Col span={24} className="title">
        <p className="title text-2xl md:text-5xl text-center">
          Look Fabulous, <b style={{ color: '#EB012A' }}>Feel Confident</b>
        </p>
      </Col>
      <Col
        span={24}
        className="illustration-container mt-1"
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <img src={outroIllustration} alt="stylemate" className="w-[148px] md:w-[282px]" />
      </Col>
      <Col span={24} className="description text-sm md:text-xl">
        <p className="text-center">
          At Stylemate, we invest time in truly getting to know you, considering everything from your age to your lifestyle. 
          This allows us to provide personalized recommendations that are perfectly tailored to your unique needs.<br/>
          With meticulous attention to detail, we are committed to enhancing your style journey. Welcome to Stylemate, your ultimate fashion solution.
        </p>
      </Col>
      <Col span={24} className="flex justify-center">
        <CTA className="mt-6 md:mt-8 h-12 w-52"/>
      </Col>
    </Row >
  );  
}

export default Outro;


